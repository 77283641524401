import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import SocialLinks from "../components/social-links"

import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutPage = ({data}) => (
  <Layout>
    <SEO title="About Brad Wade" />
      <div style={{ maxWidth: 425}}>
        <h1>About Me</h1>
        <p style={{ fontSize: `125%` }}>I am a front-end web architect, design-system evangelist, open-source believer, and musician living in beautiful Portland, Oregon.</p>
        <Img fluid={data.file.childImageSharp.fluid} />
        <p>I have built websites for higher-education, government, entertainment, television, publishing, retail, non-profit and health care clients. I’ve freelanced, ran my own business, worked at small start-ups, a Drupal shop, a digital agency, and for large international corporations. I’ve made websites for Sony Music, Time Inc., NBCU, and the Department of Energy.</p>
        <p>Currently I lead a team of engineers as the web development architect for Memorial Sloan Kettering Cancer Center.</p>

        <p>While not on the web, you may find me enjoying tennis with my kids, playing jazz piano, or exploring my new home in the Pacific Northwest.</p>

        <SocialLinks />
      </div>
  </Layout>
)

export default AboutPage


export const query = graphql`
  query {
    file(relativePath: { eq: "bradwade-crop.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 75) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`